<template>
	<b-modal @ok="$emit('ok')" id="modal-confirmEnd" title="Ending session">
		<p>
            Are you sure you would like to end the session?
            Any unsaved changes would be lost.
        </p>
		<template #modal-footer="{ ok, cancel }">
			<b-button size="md" variant="danger" @click="ok()">
				Yes
			</b-button>
            <b-button size="md" variant="primary" @click="cancel()">
				Cancel
			</b-button>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: "HarvestConfirmEndModal"
}
</script>
