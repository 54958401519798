<template>
	<b-modal id="modal-editharvest" title="Edit a harvest">
        <b-form-group
			label-cols-sm="4"
			label-cols-lg="3"
			label="Quantity:"
		>
            <b-form-input
				v-model="form.quantity"
				type="number"
                min="1"
                step="1"
				required
			></b-form-input>
		</b-form-group>
        <b-form-group
			label-cols-sm="4"
			label-cols-lg="3"
			label="Label:"
		>
            <v-select
                :clearable="false"
                :options="labelOptions"
                v-model="form.label"
                label="label"
                :reduce="l => l.value"
            />
		</b-form-group>
		<template #modal-footer>
			<b-button size="md" variant="success" @click="editHarvest()">
				Submit
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import axios from "axios";
import store from "../../store";
export default {
	name: "HarvestEditModal",
	props: ["farm"],
	data(){
		return {
            form: {
                label: -1,
                quantity: -1
            },
            labelOptions: [{value: 0, label: 'reject'}, {value: 1, label: 'accept'}]
		}
	},
    watch: {
        harvest(newVal){
            this.form = { label: newVal?.label, quantity: newVal?.quantity };
        }
    },
    computed: {
        harvest: function(){ return store.state.harvest; }
    },
	methods: {
		editHarvest(){
			axios.put(`${this.farm}/harvests/data/${this.harvest._id}`, this.form)
			.then((response) => {
				console.log(response);
				this.$bvModal.hide("modal-editharvest");
                this.$emit('success', "updated harvest data");
			})
			.catch((err) => {
				console.error(err);
				this.$bvModal.hide("modal-editharvest");
                this.$emit('error', `Error updating the quantity of harvest`);
			})
		},
	}
}
</script>
