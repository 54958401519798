<template>
	<div id="harvestDataCard">
		<div class="harvestData_card box-shadow-1">
			<div class="harvestData_card__info-col">
                <h5>#{{ harvest.capId }}</h5>
				<div>
                    <div class="pending" v-if="harvest.status == 'pending'"></div>
                    <div class="done" v-else-if="harvest.status == 'done'"></div>
                    <div class="failed" v-else-if="harvest.status == 'failed'"></div>
                </div>
				<h5>{{ harvest.quantity | qtyDisp }}</h5>
				<h5>{{ harvest.mass }} g</h5>
                <div>{{ harvest.variant }}</div>
                <div :class="{accept: harvest.label==1, reject: harvest.label==0}">{{ harvest.label  | labelDisp}}</div>
			</div>
			
			<div class="harvestData_card__btn-col" v-show="harvest.status=='done'">
				<b-button
					v-show="!harvest.expireAt"
					size="sm"
					variant="secondary"
					v-b-modal.modal-editharvest
					@click="setHarvestToEdit(harvest)"
				> <i class="fas fa-edit"></i> </b-button>
				<b-button
					v-show="!harvest.expireAt"
					@click="archiveBatch(harvest._id)"
					size="sm"
					variant="danger"
					:disabled="disabled"
				> <i class="fas fa-trash"></i> </b-button>
				<b-button
					v-show="harvest.expireAt"
					@click="undoArchiveBatch(harvest._id)"
					size="sm"
					variant="warning"
					:disabled="disabled"
				> <i class="fas fa-undo"></i> </b-button>
			</div>
		</div>
	
	</div>
	
</template>

<script>
import axios from 'axios';
export default {
	name: "HarvestDataCard",
	props : ["farm", "harvest", "disabled"],
	filters: {
		qtyDisp: function(value){
			return `${value} ${value > 1 ? 'units' : 'unit'}`;
		},
		labelDisp: function(value){
			return `${value > 0?'ACCEPT':'REJECT'}`;
		}
	},
	data(){
		return {			
		}
	},
	methods : {
		archiveBatch(hid){
			console.log(`Archiving batch ${JSON.stringify(hid)}`);
			this.batchUpdate = true;
			axios
				.put(`/${this.farm}/harvests/data/archive/${hid}`)
				.then((response) => {
					console.log(response.data);
					this.$emit('success', `updated batch`);
				})
				.catch((e) => {
					console.log(e);
					this.$emit('error', `error updating batch`);
				});
		},
		undoArchiveBatch(hid){
			console.log(`Undo archiving batch ${JSON.stringify(hid)}`);
			this.batchUpdate = true;
			axios
				.put(`/${this.farm}/harvests/data/unarchive/${hid}`)
				.then((response) => {
					console.log(response.data);
					this.$emit('success', `updated batch`);
				})
				.catch((e) => {
					console.log(e);
					this.$emit('error', `error updating batch`);
				});
		},
		setHarvestToEdit(harvest){
			this.$store.commit('setHarvestToEdit', harvest);
		}
	}
}
</script>

<style>
.harvestData_card {
	padding: 10px;
	margin: 20px 0;
	text-align: left;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;	
}

.harvestData_card__info-col {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;	
	text-overflow: ellipsis;
	white-space: nowrap;
	-moz-white-space: nowrap;
	overflow: hidden;
}

.harvestData_card__info-col * {
	margin: 3px 5px;
}

.harvestData_card.total {
	background-color: lightgoldenrodyellow;
}

.harvestData_card__info-col .reject {
	border-radius: 5px;
	padding: 2px 5px;
	font-family: "courier-new", "monospace";
	color: white;
	background-color: red;
}

.harvestData_card__info-col .accept {
	border-radius: 5px;
	padding: 2px 5px;
	font-family: "courier-new", "monospace";
	color: white;
	background-color: green;
}

.harvestData_card__btn-col {
	display: flex;
	flex-direction: row;
	justify-content: right;
	align-items: center;
	padding-right: 0;
}

.harvestData_card__btn-col * {
	margin: 5px 5px 5px 5px;
}
</style>