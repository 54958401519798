<template>
	<b-modal id="modal-addzone" title="Add new Growarea">
		<b-form-group
			label-cols-sm="4"
			label-cols-lg="3"
			label="Zone Name:"
			label-for="ins-ga"
		>
			<b-input
				type="text"
				id="ins-ga"
				v-model="zoneName"
			/>
		</b-form-group>
		<template #modal-footer>
			<b-button size="md" variant="success" @click="insertZone()">
				Submit
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import axios from "axios";
export default {
	name: "HarvestaddzoneModal",
	props: ["farm"],
	data(){
		return {
			zoneName: "",
		}
	},
	methods: {
		insertZone(){
			axios.post(`/${this.farm}/zone`, { zone: this.zoneName })
			.then((response) => {
				console.log(response);
				this.$bvModal.hide("modal-addzone");
				this.$emit('updateZones');
			})
			.catch((err) => {
				console.error(err);
				this.$emit('error', `Error adding new growarea`);
			})
		},
	}
}
</script>
